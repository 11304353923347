<template>
  <div class="container">
    <!-- Quadrados à esquerda -->
    <div
      v-if="showSquares"
      class="square square1 left"
      @click="toggleSquares"
    ></div>

    <!-- Conteúdo principal -->
    <div v-if="stage == 1" class="content">
      <h1 class="titulo">Olá, {{ $store.getters.user.user.first_name }}!</h1>
      <p class="paragrafo">
        Estamos felizes em vê-lo de volta ao nosso novo painel. Descobrimos que
        você já tem uma conta no nosso painel antigo e gostaríamos de tornar a
        sua transição para esta versão mais fácil e produtiva.
      </p>
      <p class="paragrafo">
        Você está prestes a dar um grande passo ao migrar seus dados para esta
        nova versão. Fique tranquilo, pois estamos aqui para ajudar! Temos a
        capacidade de transferir com sucesso seus leads, formulários, pipelines,
        usuários, tags e domínios para garantir que você aproveite ao máximo.
      </p>
      <p class="paragrafo">
        Queremos que você saiba que estamos comprometidos em fornecer a você a
        melhor experiência possível. Sinta-se à vontade para explorar todas as
        novas funcionalidades e melhorias que oferecemos nesta versão e, se
        precisar de alguma ajuda ou tiver alguma dúvida, não hesite em entrar em
        contato conosco.
      </p>
      <p class="paragrafo paragrafo2">
        Estamos ansiosos para trabalhar com você nesta jornada de atualização e
        esperamos que você aproveite ao máximo o nosso novo painel.
      </p>

      <div class="buttons">
        <BaseButton variant="link-info" @click="abortMigration" class="mr-4">Não, Obrigado</BaseButton>
        <BaseButton @click="makeMigration()" variant="primary" class="mr-4">Sim! Quero importar meus dados</BaseButton>
      </div>
    </div>

    <div v-else>
      <div>
        <div v-if="!loading" class="form-center">
          <h1 class="titulo">Quase lá!</h1>

          <p class="paragrafo xx">
            Falta pouco para ter seus antigos dados no Greenn Sales, por favor
            insira as credenciais de email e senha da G Digital 1.0.
          </p>

          <form @submit.prevent="makeMigration">
            <b-form-group label="E-mail">
              <b-form-input
                v-model="email"
                name="email"
                placeholder="E-mail"
                v-validate="'required|email'"
              ></b-form-input>
              <b-form-invalid-feedback
                id="errorName"
                :state="!errors.has('email')"
              >
                <span v-show="errors.has('email')" class="text-danger">{{
                  errors.first("email")
                }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Senha">
              <b-form-input
                v-model="password"
                name="senha"
                type="password"
                placeholder="Digite sua senha"
                v-validate="'required'"
              ></b-form-input>

              <b-form-invalid-feedback
                id="errorName"
                :state="!errors.has('senha')"
              >
                <span v-show="errors.has('senha')" class="text-danger">{{
                  errors.first("senha")
                }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <span v-if="failed" class="text-danger"
              >E-mail ou senha estão incorretos</span
            >

            <BaseButton
              @click="makeMigration"
              variant="primary"
              class="w-100 mt-4"
              >Importar dados!</BaseButton
            >
          </form>
        </div>
      </div>
      <h2 v-if="loading">
        Estamos importando seus dados, aproveite o Greenn Sales
      </h2>
      <h2 v-if="loading" class="sub-title">
        Logo você será redirecionado para a Dashboard
      </h2>
    </div>

    <!-- Quadrados à direita -->
    <div
      v-if="showSquares"
      class="square square2 right"
      @click="toggleSquares"
    ></div>
  </div>
</template>

<script>
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

import BackupService from "@/services/resources/BackupService";
const serviceBackup = BackupService.build();
export default {
  data() {
    return {
      showSquares: true,
      email: "",
      password: "",
      stage: 1,
      loading: false,
      failed: false,
    };
  },
  created() {
    this.checkIfHasExport();
  },
  methods: {
    toggleSquares() {
      this.showSquares = !this.showSquares;
    },
    abortMigration() {
      var migration = {
        meta_key: "imported",
        meta_value: "dont",
      };
      serviceSettings
        .create(migration)
        .then((resp) => {
          this.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {});
    },
    checkIfHasExport() {
      serviceBackup
        .create({ only_check: true, dont_show: true })
        .then((resp) => {
          if (resp == "redirect") {
            this.$router.push({ name: "Dashboard" });
          }
        });
    },
    makeMigration() {
      var that = this;
      if (this.stage == 1) {
        this.stage = 2;
        return;
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          let data = {
            email: this.email,
            password: this.password,
            subdomain: this.$store.getters.user.user.tenant_subdomain,
          };

          serviceBackup
            .create(data)
            .then(() => {
              that.$router.push({ name: "Dashboard" });
            })
            .catch((err) => {
              if (err.response.status == 422) {
                that.failed = true;
              }

              if (err.response.status == 400) {
                this.$bvToast.toast(
                  "Ocorreu um erro ao importar seus dados, chame o suporte",
                  {
                    title: "Importação",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );

                
                setTimeout(() => {
                  this.abortMigration();
                }, 2500);
              }

              that.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  text-align: center;
  padding: 20px;

  display: grid;
  gap: 20px;

  .buttons{
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
  }
}
@media (max-width: 1280px) {
  .content {
    width: 80%;
  }
}

.titulo {
  font-size: 3em;
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 50px;
}

.paragrafo2 {
  margin-bottom: 50px;
}
.paragrafo {
  color: #8c8a97;
  text-align: justify;
}

.square {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 80%;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
  cursor: pointer;
  border-radius: 15px;
  animation: rotatex 10s infinite;
  z-index: -1;
}

.square1 {
  width: 150px;
  height: 150px;
  animation: rotatex 5s infinite;
}

.square2 {
  animation: rotatex 10s infinite;
}

.left {
  left: 5%;
  top: 10%;
  background: linear-gradient(to right, var(--greenn-dark), var(--greenn));
}

.right {
  right: 5%;
  background: linear-gradient(to left, var(--greenn-dark), var(--greenn));
}

.square.hidden {
  transform: translateX(-50px);
}

@keyframes rotatex {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}
input {
  margin-bottom: 10px !important;
  &::placeholder {
    font-size: 14px !important;
  }
}
form {
  width: 50%;
}
.form-center {
  display: grid;
  place-items: center;
  gap: 20px;
}
h2 {
  color: #006b63;
  opacity: 0;
  transform: translateY(200%);
  animation: fadein 1.5s forwards;
  font-size: 2em;
}
.sub-title {
  font-size: 1.2em;
  text-align: center;
  animation: fadein 2s forwards !important;
  margin: 10px 0;
}

@keyframes fadein {
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

span {
  font-size: 14px;
}
.xx {
  width: 52%;
}
</style>
